import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class EventService {
    constructor(private http: HttpClient) { }
    saveEvent(event_name: any,options,end_registration: any,event_venue:any,reporting:any,institute:any) {
        var formData = {
            'event_name': event_name,
            'reporting':reporting,
            'end_registration': end_registration,
            'slotEvent': options,
            'institutes':institute,
            event_venue:event_venue
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events/create`, formData).pipe(map(user => {
            return user;
        }));
    }

    updateEvent(id:any,event_name: any,options,end_registration: any,event_venue:any,reporting:any,institute:any) {
        var formData = {
            'event_name': event_name,
            'reporting':reporting,
            "event_venue":event_venue,
            'end_registration': end_registration,
            'slotEvent': options,
            'institutes':institute
        }
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events/update?id=` + id, formData).pipe(map(user => {
            return user;
        }));
    }


    getEvents(page, search): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events?page=` + page + '&search=' + search).pipe(map(user => user));
    }

    getcreatedseat(soltid, eventid): Observable<any> {
        
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/get-seats?event_id=` + eventid + '&event_slot=' + soltid).pipe(map(user => user));
    }

    getSlots(id): Observable<any> {
       
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/slot-config/slots?event_id=` + id ).pipe(map(user => user));
    }

    deleteEvent(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events/delete/` + id).pipe(map(user => {
            return user;
        }));
    }



    getSeating(id,eventid): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings?slotId=` + id +'&eventId='+eventid).pipe(map(user => user));
    }

    updateSeatOrder(id:any,sort_order) {
        var formData = new FormData();
        formData.append('sort_order', sort_order);
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/update-seats-order/` + id, formData).pipe(map(user => {
         return user;
     }));
    }

    updateTotalSeat(id:any,total_seats) {
        var formData = new FormData();
        formData.append('total_seats', total_seats);
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/update-seats/` + id, formData).pipe(map(user => {
         return user;
     }));
    }


    updateInstituteSeat(id:any,slotid:any,instcode:any,seats:any) {
        var formData = new FormData();
        formData.append('event_id', id);
        formData.append('event_slot_id', slotid);
        formData.append('institute_code', instcode);
        formData.append('booked_seats', seats);
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/slot-config/update`, formData).pipe(map(user => {
         return user;
     }));
    }

    updateSeatAlign(id:any,total_seats) {
        var formData = new FormData();
        formData.append('seat_align', total_seats);
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/update-seat-align/` + id, formData).pipe(map(user => {
         return user;
     }));
    }

    updateInstitutioncolor(id:any,total_seats) {
        var formData = new FormData();
        formData.append('institute_color', total_seats);
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/update-seat-color/` + id, formData).pipe(map(user => {
         return user;
     }));
    }

    createseat(slotId,eventId,rows,total) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/no-seats-create`,{event_slot:slotId,event:eventId,array_rows:JSON.stringify(rows),total_rows:total}).pipe(map(user => {
         return user;
     }));
    }

    sendEmail(slotId,eventId) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/send-bulk-email/`+slotId+'?event='+eventId).pipe(map(user => {
         return user;
     }));
    }

 
    getSeat(id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/get-seats/`+ id).pipe(map(user => user));
    }

    downloadleft(id): Observable<any> {
        var formData = new FormData();
        formData.append('seating', "LEFT");
        const file =this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/download-seats/`+id, formData,{ responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadreadinglist(id): Observable<any> {
        const file =this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings?`+id, {},{ responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    getinstlist(slotid,eventid): Observable<any> {
        const file =this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/institute/store-seat`, {eventId:eventid,eventSlotId:slotid}).pipe(map(user => {
            return user
        }));
        return file;
    }

  

    downloadstickerspdf(id,side): Observable<any> {
        var formData = new FormData();
        formData.append('seating', side);
        const file =this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/download-stickers/`+id,formData,{ responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadright(id): Observable<any> {
        var formData = new FormData();
        formData.append('seating', "RIGHT");
        const file =this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/download-seats/`+id ,formData,{ responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }
    

}
