import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }
  saveInsuser(institution, event, name, mobile_number, email, role_id, role_type) {
    var formData = {
      'institution': institution,
      'name': name,
      'eventId': event,
      'mobile_number': mobile_number,
      'email': email,
      'role_id': role_id,
      'role_type': role_type
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/create`, formData).pipe(map(user => {
      return user;
    }));
  }

  saveEventConfig(event, slot, institution) {
    var formData = {
      'event_id': event,
      'event_slot_id': slot,
      'institute_code': JSON.stringify(institution),
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/slot-config/create`, formData).pipe(map(user => {
      return user;
    }));
  }

  registartionclose(event, day, event_time) {
    var formData = {
      'eventId': event,
      'date': day,
      'time': event_time,
      // 'event_time':event_time
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events/end-registration`, formData).pipe(map(user => {
      return user;
    }));
  }

  setting(slot, event, day, event_time) {
    var formData = {
      'event': slot,
      'date': day,
      'time': event_time,
      'event_slot': event,
      // 'event_time':event_time
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/gate-close-create`, formData).pipe(map(user => {
      return user;
    }));
  }

  updatecolumn(event, rows, columndate) {
    var formData = {
      'total_rows': rows,
      'event_slot': event,
      'event': columndate
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/no-seats-create`, formData).pipe(map(user => {
      return user;
    }));
  }

  // columns(event, day, ) {
  //   var formData = {
  //     'event': event,
  //     'day': day,
  //   }
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/gate-close-create`, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }



  updateInsuser(id, institution, event, name, mobile_number, email, role_id, role_type) {
    var formData = {
      'institution': institution,
      'name': name,
      'mobile_number': mobile_number,
      'email': email,
      'eventId': event
      // 'role_id': role_id,
      // 'role_type': role_type
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  updateEventConfig(id, event, slot, institution) {
    var formData = {
      'event_id': event,
      'event_slot_id': slot,
      'institute_code': JSON.stringify(institution),

      // 'role_id': role_id,
      // 'role_type': role_type
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/slot-config/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }




  saveFooduser(event, name, mobile_number, email, role_id, role_type) {
    var formData = {
      'eventId': event,
      'name': name,
      'mobile_number': mobile_number,
      'email': email,
      'role_id': role_id,
      'role_type': role_type
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/create-venue-users`, formData).pipe(map(user => {
      return user;
    }));
  }

  updateFooduser(id, event, name, mobile_number, email, role_id, role_type) {
    var formData = {
      'eventId': event,
      'name': name,
      'mobile_number': mobile_number,
      'email': email,
      // 'role_id': role_id,
      // 'role_type': role_type
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/update-venue-users/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  createreason(reason) {
    var formData = {
      'reason': reason,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hold-reason/create`, formData).pipe(map(user => {
      return user;
    }));
  }

  createnews(reason, event) {
    var formData = {
      'description': reason,
      'eventId': event
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/news/create`, formData).pipe(map(user => {
      return user;
    }));
  }

  updatereason(id, reason) {
    var formData = {
      'reason': reason,
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hold-reason/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  updatenews(id, reason,event) {
    var formData = {
      'description': reason,
      'eventId':event
    }
    return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/news/update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  getInsuser(page, search, role_type): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin?role_type=` + role_type + '&page=' + page + '&search=' + search).pipe(map(user => user));
  }

  gethelplinenumber(page, search): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/institute/institute-details?search=` + search + '&page=' + page).pipe(map(user => user));
  }
  gethelplinenumberfilter(page, search, filter): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/institute/institute-details?search=` + search + '&page=' + page + '&institution=' + filter).pipe(map(user => user));
  }

  getInsuserfilter(page, search, institution, role_type): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin?role_type=` + role_type + '&page=' + page + '&search=' + search + '&institution=' + institution).pipe(map(user => user));
  }


  getInstitute(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/institute`).pipe(map(user => user));
  }

  uploadInsuser(file: File, id: any, type: any) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
      formData.append('role_id', id);
      formData.append('role_type', type);
      return this.http
        .post<any>(
          ` ${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/create-excel`,
          formData
        )
        .pipe(
          map((user) => {
            return user;
          })
        );
    }

  }


  uploadHelplineNumber(file: File) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
      return this.http
        .post<any>(
          ` ${environment.apiUrl}${environment.apiPrefix}/admin/institute/details-excel-upload`,
          formData
        )
        .pipe(
          map((user) => {
            return user;
          })
        );
    }

  }

  uploadFooduser(file: File, id: any, type: any) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
      formData.append('role_id', id);
      formData.append('role_type', type);
      return this.http
        .post<any>(
          ` ${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/create-excel-venue-users`,
          formData
        )
        .pipe(
          map((user) => {
            return user;
          })
        );
    }
  }

  deleteUser(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/delete-users/` + id).pipe(map(user => {
      return user;
    }));
  }

  deletereason(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hold-reason/delete/` + id).pipe(map(user => {
      return user;
    }));
  }

  deletenews(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/news/delete/` + id).pipe(map(user => {
      return user;
    }));
  }

  deleteEventConfig(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/slot-config/delete/` + id).pipe(map(user => {
      return user;
    }));
  }

  deleteAllUser(type: string) {
    var formData = {
      'type': type,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/delete-all-users`, formData).pipe(map(user => {
      return user;
    }));
  }

  getEvents(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events`).pipe(map(user => user));
  }

  getcloselist(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/gate-close/` + id).pipe(map(user => user));
  }

  getrowcount(event, id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/no-of-seats/` + id + '?event_slot=' + event).pipe(map(user => user));
  }

  getGateCloseList(id, slotid): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/settings/gate-close/` + id + '?event_slot=' + slotid).pipe(map(user => user));
  }

  getregCloseList(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events/end-registration/` + id).pipe(map(user => user));
  }

  seatallotment(id, date): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/generate-seats/` + date + '?event_slot=' + id).pipe(map(user => user));
  }

  generateSeat(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/generate-seats/` + id).pipe(map(user => user));
  }

  getVenueuser(page, search, role_type): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sub-admin/venue-food-users?role_type=` + role_type + '&page=' + page + '&search=' + search).pipe(map(user => user));
  }

  getReason(page, search): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hold-reason?page=` + page + '&search=' + search).pipe(map(user => user));
  }

  getNews(page, search): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/news?page=` + page + '&search=' + search).pipe(map(user => user));
  }


  getConfig(id: any, search: any): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/slot-config?event_slot_id=`
      + id + '&search=' + search
    ).pipe(map(user => user));
  }

  getCount(id: any): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/count/`
      + id
    ).pipe(map(user => user));
  }

  getBookedseat(id: any, search): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/slot-config/count?event_slot_id=`
      + id + '&search=' + search
    ).pipe(map(user => user));
  }

}
